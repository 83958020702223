import React from 'react'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ensureReady, After } from '@jaredpalmer/after'
import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import ErrorBoundary from './components/ErrorBoundary'
import Container from '../common/container'
import configureStore from '../common/store'
import routes from '../routes'

OfflinePluginRuntime.install()

const store = configureStore(window.__PRELOADED_STATE__)

ensureReady(routes).then(data =>
  hydrate(
    <ErrorBoundary>
      <BrowserRouter>
        <Provider store={store}>
          <Container>
            <After data={data} routes={routes} />
          </Container>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById('root')
  )
)

if (module.hot) {
  module.hot.accept()
}
