import React from 'react'

import { asyncComponent } from '@jaredpalmer/after'

export default [
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./client/pages/home'),
    }),
  },
  {
    path: '/change-payment',
    exact: true,
    component: asyncComponent({
      loader: () => import('./client/pages/change-payment'),
    }),
  },
  {
    path: '/about',
    exact: true,
    component: asyncComponent({
      loader: () => import('./client/pages/about'),
    }),
  },
  {
    path: '/contact',
    exact: true,
    component: asyncComponent({
      loader: () => import('./client/pages/contact'),
    }),
  },
  {
    path: '/privacy',
    exact: true,
    component: asyncComponent({
      loader: () => import('./client/pages/privacy'),
    }),
  },
  {
    path: '/terms',
    exact: true,
    component: asyncComponent({
      loader: () => import('./client/pages/terms'),
    }),
  },
]
