import { createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase'
import { reduxFirestore, firestoreReducer } from 'redux-firestore'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.RAZZLE_FIREBASE_APIKEY,
  authDomain: process.env.RAZZLE_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.RAZZLE_FIREBASE_DATABASEURL,
  projectId: process.env.RAZZLE_FIREBASE_PROJECTID,
  storageBucket: process.env.RAZZLE_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.RAZZLE_FIREBASE_MESSAGINGSENDERID,
}

function getOrCreateFirebaseInstance(config) {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }

  return firebase
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  updateProfileOnLogin: true,
  useFirestoreForProfile: true,
}

// Add Firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
})

const configureStore = initialState => {
  const firebase = getOrCreateFirebaseInstance(firebaseConfig)
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      reactReduxFirebase(firebase, rrfConfig),
      reduxFirestore(firebase)
    )
  )
  // return createStoreWithFirebase(
  //   rootReducer,
  //   preloadedState
  //   // typeof window !== 'undefined' &&
  //   //   window.__REDUX_DEVTOOLS_EXTENSION__ &&
  //   //   window.__REDUX_DEVTOOLS_EXTENSION__()
  // )
}

export default configureStore
