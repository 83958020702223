import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'

class ThemeSwitcher extends Component {
  constructor(props) {
    super(props)
    const theme =
      (typeof window !== 'undefined' &&
        window.localStorage &&
        window.localStorage.getItem('theme')) ||
      'light'
    this.state = { theme }
  }

  changeTheme = (e = null) => {
    if (e && e.preventDefault) {
      e.preventDefault
    }
    const theme = this.state.theme === 'light' ? 'dark' : 'light'
    window.localStorage.setItem('theme', theme)
    this.setState({ theme })
  }

  render() {
    const { theme } = this.state
    const switchText = `Switch to ${theme === 'light' ? 'dark' : 'light'} theme`
    return (
      <Fragment>
        <Helmet>
          <html className={`${theme === 'dark' ? 'dark' : 'light'}-theme`} />
        </Helmet>
        <button
          onClick={this.changeTheme}
          className={'theme-switcher theme-switcher--is-' + theme}
          title={switchText}
        >
          {switchText}
        </button>
      </Fragment>
    )
  }
}

export default ThemeSwitcher
