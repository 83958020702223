import React, { Fragment } from 'react'
import ThemeSwitcher from '../client/components/ThemeSwitcher'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../client/styles/base.css'

toast.configure({
  position: 'bottom-right',
})

const Container = ({ children, reduxStore, ...props }) => (
  <Fragment>
    <header className="header">
      <div className="wrapper">
        <h1 className="title">
          <Link to="/">todo.tax</Link>
        </h1>
        <ThemeSwitcher />
      </div>
    </header>
    {children}
    <footer>
      <div className="wrapper">
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/privacy">Privacy</Link>
        <Link to="/terms">Terms</Link>
        <a href="https://grant.codes">Made by grant.codes</a>
      </div>
    </footer>
  </Fragment>
)

export default Container
